import React from 'react'

export default function Footer() {
    return (
        <footer className="bg-black text-white">
            <div className="container">
            <div className="row">
                <div className="text-center">&copy; Repairque 2021. All Rights Reserved.</div>
            </div>
            </div>
        </footer>
    )
}