import React from 'react'
import logo from '../../logo.svg';
import {
  Switch,
  NavLink,
} from "react-router-dom";
import { firebase } from '../config/firebase';

import { useState } from "react";
// import { googleProvider } from '../config/authMethods';
// import socialMediaAuth from '../service/auth';

export default function Header() {
  // const handleOnClick = async (provider) => {
  //   const res = await socialMediaAuth(provider);
  //   console.log(res);
  // }
  const SignInWithFirebase = () => {
    var googleProvider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(googleProvider)
    .then((re) => {
      console.log(re);
      //  console.log("pass");
    })
    .catch((err) => {
      console.log(err);
      //  console.log("Fail");
    });
  }

  const signOut = () => {
    firebase.auth().signOut();
  }

  const [isUserSignedIn, setIsUserSignedIn] = useState(true);

  firebase.auth().onAuthStateChanged((user) => {
    if(user){
      return setIsUserSignedIn(true);
    }
    setIsUserSignedIn(false);
  })

  if(isUserSignedIn === true){
    return (
      <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-black fixed-top pt-3 pb-3">
        <div className="container">
          <p className="navbar-brand">
            <img src={logo} alt="" width="24" height="24" className="pt-1 d-inline-block align-text-top"/> Repairqlu
          </p>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <Switch>
                <NavLink exact to="/" className="main-nav" activeClassName="main-nav-active">
                  <li className="nav-item">
                    <p className="nav-link">Home</p>
                  </li>
                </NavLink>
              </Switch>
              <Switch>
                <NavLink exact to="/serviceproviders" className="main-nav" activeClassName="main-nav-active">
                  <li className="nav-item">
                    <p className="nav-link">Service Providers</p>
                  </li>
                </NavLink>
              </Switch>
              <Switch>
                <NavLink exact to="/serviceseekers" className="main-nav" activeClassName="main-nav-active">
                  <li className="nav-item">
                    <p className="nav-link">Service Seekers</p>
                  </li>
                </NavLink>
              </Switch>
              <Switch>
                <NavLink exact to="/map" className="main-nav" activeClassName="main-nav-active">
                  <li className="nav-item">
                    <p className="nav-link">Garage Location</p>
                  </li>
                </NavLink>
              </Switch>
              <li className="nav-item">
                <p className="nav-link text-white">
                  <button className="btn btn-sm btn-danger mx-2" onClick={signOut}>Sign Out</button>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      </>
    )
    
  } else {
    return (
      <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-black fixed-top pt-3 pb-3">
        <div className="container">
          <p className="navbar-brand">
            <img src={logo} alt="" width="24" height="24" className="pt-1 d-inline-block align-text-top"/> Repairqlu
          </p>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <Switch>
                <NavLink exact to="/" className="main-nav" activeClassName="main-nav-active">
                  <li className="nav-item">
                    <p className="nav-link">
                        Home
                    </p>
                  </li>
                </NavLink>
              </Switch>
              <Switch>
                <NavLink exact to="/serviceproviders" className="main-nav" activeClassName="main-nav-active">
                  <li className="nav-item">
                    <p className="nav-link">Service Providers</p>
                  </li>
                </NavLink>
              </Switch>
              <Switch>
                <NavLink exact to="/serviceseekers" className="main-nav" activeClassName="main-nav-active">
                  <li className="nav-item">
                    <p className="nav-link">Service Seekers</p>
                  </li>
                </NavLink>
              </Switch>
              <Switch>
                <NavLink exact to="/map" className="main-nav" activeClassName="main-nav-active">
                  <li className="nav-item">
                    <p className="nav-link">Garage Location</p>
                  </li>
                </NavLink>
              </Switch>
              <li className="nav-item">
                <p className="nav-link text-white">
                  {/* <button type="button" className="btn btn-sm btn-warning" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Log In
                  </button> */}
                  <button className="btn btn-sm btn-warning mx-2" onClick={SignInWithFirebase}>Sign In</button>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* <div className="modal" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content text-center">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
  
            <div className="modal-body form-signin col-md-4 offset-md-4">
              <form>
                <img className="mb-2" src={logo} alt="" width="72" height="57" />
                <h1 className="h3 mb-2 display-5 fw-bold">Log In</h1>
                <p>Not a member? Sign Up</p>
                <button className="w-100 btn mb-2 btn-danger" onClick={() => handleOnClick(googleProvider)}>Google</button>
                <button className="w-100 btn mb-2 btn-danger" onClick={SignInWithFirebase}>Google</button>
                <p>OR</p>
  
                <div className="form-floating mb-2">
                  <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                  <label htmlFor="floatingInput">Email address</label>
                </div>
                <div className="form-floating">
                  <input type="password" className="form-control" id="floatingPassword" placeholder="Password" />
                  <label htmlFor="floatingPassword">Password</label>
                </div>
  
                <div className="checkbox mb-2">
                  <label>
                    <input type="checkbox" value="remember-me" /> Remember me
                  </label>
                </div>
                <button className="w-100 btn btn-lg btn-warning" type="submit">Sign in</button>
              </form>
            </div>
  
          </div>
        </div>
      </div> */}
      </>
    )    
  }
}
