import React from 'react';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete';
import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";

export const Search = ({panTo}) =>  {
    const {
        ready,
        value,
        suggestions: {status, data},
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete({
        requestOptions: {
            location: {
                lat: () => 22.572645,
                lng: () => 88.363892,
            },
            radius: 200 * 1000,
        },
    });

    return (
    <div className="search">
        <Combobox 
            onSelect = {async (address) => {
                setValue(address, false);
                clearSuggestions();

                try {
                    const result = await getGeocode({address});
                    const {lat, lng} = await getLatLng(result[0]);
                    panTo({lat, lng});
                    // console.log(lat, lng);
                } catch(error) {
                    console.log("Search Select Error!"+ error);
                }
            }}
        >
            <ComboboxInput
                value={value}
                onChange = {(e) => {
                    setValue(e.target.value);
                }}
                disabled = {!ready}
                placeholder = "Search Service Providers"
            />       

            <ComboboxPopover className="boxList">
                <ComboboxList>
                    {status === "OK" && data.map(({ id, description }) => (
                        <ComboboxOption key={id} value={description} />
                    ))}
                </ComboboxList>
            </ComboboxPopover>
        </Combobox>
    </div>
    )
}
