import React from 'react'
import wheel from '../../images/wheel.svg';
import dentingpainting from '../../images/dentingpainting.svg';
import heatingcooling from '../../images/heatingcooling.svg';
import carwash from '../../images/carwash.svg';
import battery from '../../images/battery.svg';
import enginebreakdown from '../../images/enginebreakdown.svg';
import wiperglass from '../../images/wiperglass.svg';
import carspa from '../../images/carspa.svg';
import clutchbrake from '../../images/clutchbrake.svg';
import engineoil from '../../images/engineoil.svg';

export const ChatBot = () => {
    const onBox  = () => {
        var x = document.getElementById("msgBox");
        if (window.getComputedStyle(x).visibility === "hidden") {
            x.style.visibility = "visible";
        } else {
            x.style.visibility = "hidden";
        }
    }
    return (
        <>
        <div id="msgBox" className="container text-center">
            <h5>Select Repair Type</h5>
            <hr/>
            <div className="row">
                <div className="col-1"></div>
                <div className="col-2">
                    <img src={engineoil} className="img-fluid rounded-start" alt="..."/>
                    <span class="dec">Engine Oil</span>
                </div>
                <div className="col-2">
                    <img src={carspa} className="img-fluid rounded-start" alt="..."/>
                    <span class="dec">Car Spa</span>
                </div>
                <div className="col-2">
                    <img src={clutchbrake} className="img-fluid rounded-start" alt="..."/>
                    <span class="dec">ClutchBrake</span>
                </div>
                <div className="col-2">
                    <img src={wheel} className="img-fluid rounded-start" alt="..."/>
                    <span class="dec">Wheel</span>
                </div>
                <div className="col-2">
                    <img src={dentingpainting} className="img-fluid rounded-start" alt="..."/>
                    <span class="dec">DentingPainting</span>
                </div>
                <div className="col-1"></div>
            </div>
            <div className="row">
                <div className="col-1"></div>
                <div className="col-2">
                    <img src={battery} className="img-fluid rounded-start" alt="..."/>
                    <span class="dec">Battery</span>
                </div>
                <div className="col-2">
                    <img src={carwash} className="img-fluid rounded-start" alt="..."/>
                    <span class="dec">Car Wash</span>
                </div>
                <div className="col-2">
                    <img src={wiperglass} className="img-fluid rounded-start" alt="..."/>
                    <span class="dec">Wiper Glass</span>
                </div>
                <div className="col-2">
                    <img src={heatingcooling} className="img-fluid rounded-start" alt="..."/>
                    <span class="dec">HeatingCooling</span>
                </div>
                <div className="col-2">
                    <img src={enginebreakdown} className="img-fluid rounded-start" alt="..."/>
                    <span class="dec">EngineBreakdown</span>
                </div>
                <div className="col-1"></div>
            </div>
            <span style={{backgroundColor: '#FFC107'}}>Selected Service Providor Name, Details , Rating</span>
            <input type="text" placeholder="Type Here..."/>
        </div>
        <span className="chatBot" onClick = {onBox}>
            <strong>Chat Me</strong>
        </span>
        </>
    )
}
