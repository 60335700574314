import React from 'react'
import cover from '../images/converge.jpg';
import integrations from '../images/integrations.png';
import Group49 from '../images/Group_49.png';

export default function Serviceseekers() {
    return (
        <>
        <header className="cover pt-5 mt-4" id="cover">
            <div className="card bg-black text-white pt-4">
                <img src={cover} className="card-img" alt="..."/>
                <div className="card-img-overlay d-flex align-content-center flex-wrap">
                    <div className="container card-body px-5">
                        <h1 className="card-title display-1 fw-bold">Connected Asset</h1>
                        <h1 className="card-title display-1 fw-bold">Management Platform</h1>
                        <h3 className="card-text">REPAIRQUE is live from January 2022</h3>
                        {/* <button type="button" className="btn btn-outline-light">Get notified</button> */}
                    </div>
                </div>
            </div>
        </header>

        <section className="Features bg-black pt-5" id="Features">
            <div className="container pt-5 text-center text-white">
                <div className="row pt-5">
                    <div className="col-md-6">
                        <div className="container-fluid d-lg-flex justify-content-center">
                            <img src={Group49} className="img-fluid rounded-start" alt="..."/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="section-heading">
                            <h2 className="fw-bold display-5 pb-5">Features</h2>
                        </div>
                        <h4>Experience Single Source of Information</h4>
                        <p className="pt-4">
                            Service seekers for any type of asset repair could plan, 
                            monitor and maintain history of service in one single view. 
                            All repair related data could be fetched in a single click. 
                            Registers users could upload Picture, Video, Sound etc. 
                            Such transparent and prompt actions helps in getting better 
                            value for service.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section className="Features bg-black pt-5" id="Features">
            <div className="container pt-5 text-center text-white">
                <div className="row pt-5">
                    <div className="col-md-6">
                        <div className="section-heading">
                            <h2 className="fw-bold display-5 pb-5">Features</h2>
                        </div>
                        <h4>Experience Seamless Engagement</h4>
                        <p className="pt-4">
                        Service seekers would experience real-time communication with 
                        service providers. Discuss necessary details for quick and 
                        on-time repair. Both way communication and document sharing 
                        features would streamline and digitize whole engagement.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <div className="container-fluid d-lg-flex justify-content-center">
                            <img src={integrations} className="img-fluid rounded-start" alt="..."/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}