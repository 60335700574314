import React from 'react';
import { ChatBot } from '../../components/view/chatbot/ChatBot';
import { Search } from '../../components/view/search/Search';
import {
    GoogleMap,
    useLoadScript,
    Marker,
    InfoWindow,
} from "@react-google-maps/api";
import mapStyles from "../../components/styles/mapStyles";

const libraries = ["places"];
const mapContainerStyle = {
    width: "100vm",
    height: "82vh",
};

const options = {
    styles: mapStyles, // Style effect dark in map
    disableDefaultUI: true,
    zoomControl: true,
};

const center = {
    lat: 22.572645,
    lng: 88.363892,
};

// const centerPoint = (lat, lng) => {
    // if(lat === 0){
    //     console.log(lat);
    // }
    // let center;
    // if(lat === 0){
    //     center = {
    //         lat: 22.572645,
    //         lng: 88.363892,
    //     };
    // } else if(lat === 1) {
    //     return center;
    // }else {
    //     center = {
    //         lat: lat,
    //         lng: lng,
    //     };
    // }
    // center = {
    //     lat: lat,
    //     lng: lng,
    // };
    // setMarkers((current) => [
    // ...current,
    // {
    //     lat: lat,
    //     lng: lng,
    //     time: new Date(),
    // },
    // ]);
    // console.log(center);
    // return center;
// }

export default function Map() {
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    const [markers, setMarkers] = React.useState([]);
    const [selected, setSelected] = React.useState(null);

    const onMapClick = React.useCallback((e) => {
        setMarkers((current) => [
        ...current,
        {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
            time: new Date(),
        },
        ]);
        // console.log(e.latLng.lat());
    }, []);

    const mapRef = React.useRef();
    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
    }, []);

    const panTo = React.useCallback(({lat, lng}) => {
        mapRef.current.panTo({lat, lng});
        mapRef.current.setZoom(14);
        // onMapClick(latLng = {lat, lng});
        
        // let loc = centerPoint(lat, lng);
        // StorePoint(loc);
        // console.log(lat, lng);
    }, []);

    if (loadError) return "Error loading maps";
    if (!isLoaded) return "Loading Maps";
    
    let loc = center;
    // console.log(loc);

    return (
        <>
        <div className="pt-5 mt-4">
            <div className="pt-3">
                <Search panTo = {panTo}/>
                <Locate panTo = {panTo} />
                <ChatBot />
                <GoogleMap
                    id = "map"
                    mapContainerStyle = {mapContainerStyle}
                    zoom = {8}
                    center = {center}
                    options = {options}
                    // onClick = {onMapClick}
                    onLoad = {onMapLoad}
                    // onClick = {(e) => {
                    //     console.log(e);
                    // }
                >
                    {/* {markers.map(marker => (
                        <Marker 
                            key={marker.time.toISOString()} 
                            position={{lat: marker.lat, lng: marker.lng}} 
                        />
                    ))} */}
                    <StorePoint panTo={center} />
                </GoogleMap>
            </div>
        </div>
        </>
    )
}

function StorePoint(loc) {
    // console.log(loc.panTo.lat);
    let lat = loc.panTo.lat;
    let lng = loc.panTo.lng;
    // let lat = 22.572645;
    // let lng = 88.363892;
    return (
        <Marker
            // panTo={panTo}
            // position={onMapClick}
            position = {{lat: lat, lng: lng}}
        />
    )
}

function Locate({panTo}) {
    return ( 
        <button className ="locate" onClick = {() => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    panTo({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                },
                () => null
            );
        }}>
            <span><strong>GPS</strong></span>
        </button>
    )
}