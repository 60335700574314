import Header from './components/view/Header';
import Footer from './components/view/Footer';
import Home from './components/view/Home';
import Serviceseekers from './components/view/Serviceseekers';
import Serviceproviders from './components/view/Serviceproviders';
import Map from './components/view/Map';
import { Contact } from './components/view/Contact';
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import './App.css';

function App() {
  return (
    <>
    <Router>
      <Header/>
        <Route path="/" exact component={Home}>{/* <Home/> */}</Route>
        <Route path="/serviceproviders" component={Serviceproviders}></Route>
        <Route path="/serviceseekers" component={Serviceseekers}></Route>
        <Route path="/map" component={Map}></Route>
        <Route path="/contact" component={Contact}></Route>
      <Footer />
    </Router>
    </>
  );
}

export default App;
