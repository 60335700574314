import React, { useState } from 'react';
import { firebase } from '../config/firebase'

export const Contact = () => {
    var db = firebase.firestore();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [message, setMessage] = useState("");

    const [loader, setLoader] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        db.collection('contacts').add({
            name: name,
            email: email,
            address: address,
            message: message,
        })
        .then(() => {
            alert("Message has been submited");
            setLoader(false);
        })
        .catch((error) => {
            alert(error.message);
            setLoader(false);
        });
        setName("");
        setEmail("");
        setAddress("");
        setMessage("");
    };
    return (
        <div className="pt-md-5">
            <div className="pt-md-5 m-5">
                <form action="" className="form row g-1 p-5 col-md-4 offset-md-4  shadow bg-body rounded" onSubmit={handleSubmit}>
                    <div className="mb-1">
                        <label htmlFor="name" className="form-label">Full Name</label>
                        <input type="text" className="form-control" id="name" placeholder="John Doe" value={name} onChange={(e) => setName(e.target.value)} required />
                    </div>
                    <div className="mb-1">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input type="email" className="form-control" id="email" placeholder="name@example.com" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </div>
                    <div className="mb-1">
                        <label htmlFor="address" className="form-label">Address</label>
                        <input type="text" className="form-control" id="address" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} required />
                    </div>
                    <div className="mb-1">
                        <label htmlFor="message" className="form-label">Message</label>
                        <textarea className="form-control" id="message" rows="3" placeholder="Question?" value={message} onChange={(e) => setMessage(e.target.value)} required ></textarea>
                    </div>
                    <button type="submit" className="btn" style={{background : loader ? "#6C757D" : "#0D6EFD"}}>Submit</button>
                </form>
            </div>
        </div>
    )
}
