import React from 'react'
import cover from '../images/pexels.jpg';
import cash from '../images/cash-coin.svg';
import headset from '../images/headset.svg';
import trophy from '../images/trophy-fill.svg';
import efficiency from '../images/efficiency.jpg';
import transparency from '../images/transparency.jpg';
import trust from '../images/trust.jpg';
import Group50 from '../images/Group_50.png';
import integrations from '../images/integrations.png';
import {
    Switch,
    Link,
  } from "react-router-dom";

export default function Home() {
    return (
        <>
        <header className="cover pt-5 mt-4" id="cover">
            <div className="card bg-black text-white pt-4">
                <img src={cover} className="card-img" alt="..."/>
                <div className="card-img-overlay d-flex align-content-center flex-wrap">
                    <div className="container card-body px-5">
                        <h1 className="card-title display-1 fw-bold">Connected</h1>
                        <h1 className="card-title display-1 fw-bold">Service Marketplace</h1>
                        <h3 className="card-text">Beta version launching in January</h3>
                        <Switch>
                            <Link exact to="/contact">
                                <button type="button" className="btn btn-outline-light">Contact Us</button>
                            </Link>
                        </Switch>
                    </div>
                </div>
            </div>
        </header>

        <section className="about bg-black pt-5" id="about">
            <div className="pt-5">
                <div className="section-heading text-center text-white">
                    <h2 className="fw-bold display-5">What is it about?</h2>
                </div>
                <div className="row img-fluid">
                    <div id="carouselExampleDark" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-bs-interval="10000" style={{height: 400}}>
                                <div className="carousel-caption">
                                    <div className="card mb-3 bg-dark text-white">
                                        <div className="row g-0">
                                            <div className="col-4">
                                                <img src={efficiency} className="img-fluid rounded-start" alt="..."/>
                                            </div>
                                            <div className="col-8 d-flex align-content-center flex-wrap">
                                                <div className="card-body text-start">
                                                    <p className="card-text">
                                                        The interaction platform is designed to support service related activities from
                                                        searching suitable service partner to order confirmation with secure 24/7 online
                                                        operations. Every interactions are personalised, private and secure.
                                                    </p>
                                                    <h5 className="card-title">Experience efficiency</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item" data-bs-interval="5000" style={{height: 400}}>
                                <div className="carousel-caption">
                                    <div className="card mb-3 bg-dark text-white">
                                        <div className="row g-0">
                                            <div className="col-4">
                                                <img src={transparency} className="img-fluid rounded-start" alt="..."/>
                                            </div>
                                            <div className="col-8 d-flex align-content-center flex-wrap">
                                                <div className="card-body text-start">
                                                    <p className="card-text">
                                                        Our objective is to build transparency in every interactions; Verified Listing, 
                                                        Service Quality, Genuine Replacement and Digital Documentations.
                                                    </p>
                                                    <h5 className="card-title">Transparency</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item"  style={{height: 400}}>
                                <div className="carousel-caption">
                                    <div className="card mb-3 bg-dark text-white">
                                        <div className="row g-0">
                                            <div className="col-4">
                                                <img src={trust} className="img-fluid rounded-start" alt="..."/>
                                            </div>
                                            <div className="col-8 d-flex align-content-center flex-wrap">
                                                <div className="card-body text-start">
                                                    <p className="card-text">
                                                        Build the trust among all stakeholders is one of the main inspirations. 
                                                        Its marketplace for verified users and verified service partners. 
                                                        Everyone shall experience open, competitive but personalised marketplace for 
                                                        receiving and offering of services.
                                                    </p>
                                                    <h5 className="card-title">Trust</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </section>

        <section className="Features bg-black pt-5" id="Features">
            <div className="container pt-5 text-center text-white">
                <div className="section-heading">
                    <h2 className="fw-bold display-5">Features</h2>
                    <p>Above and Beyond</p>
                </div>
                <div className="row pt-5">
                    <div className="col-md-6">
                        <h4>Personalised Search</h4>
                        <p className="pt-4">
                            Personalised search and chat feature helps you in finding 
                            suitable service partner of your needs and preferences. 
                            We ensure verified listing prior to include in the marketplace
                        </p>
                    </div>
                    <div className="col-md-6">
                        <div className="d-lg-flex justify-content-center">
                            <img src={Group50} className="img-fluid rounded-start" alt="..."/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="Integrations bg-black pt-5" id="Integrations">
            <div className="container pt-5 text-center text-white">
                <div className="row pt-5">
                    <div className="col-md-6">
                        <div className="container-fluid d-lg-flex justify-content-center">
                            <img src={integrations} className="img-fluid rounded-start" alt="..."/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="section-heading">
                            <h2 className="fw-bold display-5 pb-5">Experience Integration</h2>
                        </div>
                        <div className="row">
                            <div className="card-body col-6">
                                <img src={trophy} width="48" height="48" className="img-fluid rounded-start pb-3 pt-3" alt="..."/>
                                <h4 className="card-title">Stay Informed</h4>
                                <p className="card-text">Regular Status Update</p>
                            </div>
                            <div className="card-body col-6">
                                <img src={headset} width="48" height="48" className="img-fluid rounded-start pb-3 pt-3" alt="..."/>
                                <h4 className="card-title">Unified Interaction</h4>
                                <p className="card-text">All in one place</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="card-body col-6">
                                <img src={cash} width="48" height="48" className="img-fluid rounded-start pb-3 pt-3" alt="..."/>
                                <h4 className="card-title">Secured Payment</h4>
                                <p className="card-text">Outstanding Quality</p>
                            </div>
                            <div className="card-body col-6">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}