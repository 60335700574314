import React from 'react'
import cover from '../images/service_provider.jpg';
import Group48 from '../images/Group_48.png';
import Group52 from '../images/Group_52.png';
import Group53 from '../images/Group_53.png';

export default function Serviceproviders() {
    return (
        <>
        <header className="cover pt-5 mt-4" id="cover">
            <div className="card bg-black text-white pt-4">
                <img src={cover} className="card-img" alt="..."/>
                <div className="card-img-overlay d-flex align-content-center flex-wrap">
                    <div className="container card-body px-5">
                        <h1 className="card-title display-1 fw-bold">Connected</h1>
                        <h1 className="card-title display-1 fw-bold">Service</h1>
                        <h1 className="card-title display-1 fw-bold">Platform</h1>
                        <h3 className="card-text">REPAIRQUE is live from January 2022</h3>
                        {/* <button type="button" className="btn btn-outline-light">Get notified</button> */}
                    </div>
                </div>
            </div>
        </header>

        <section className="Features bg-black pt-5" id="Features">
            <div className="container pt-5 text-center text-white">
                <div className="row pt-5">
                    <div className="col-md-6">
                        <div className="section-heading">
                            <h2 className="fw-bold display-5 pb-5">Features</h2>
                        </div>
                        <h4>Experience Seamless workorder management</h4>
                        <p className="pt-4">
                            Whether you are already using any DMS system or Excel
                            based solution, this platform provides seamless service
                            management experience. As a service provider, it helps to
                            create new workorders, prioritize emergency tasks,
                            automated update to customers. You can also schedule
                            tasks with partners, contact customers in real-time. As a
                            larger business house, monitor utilization of dispersed
                            service technicians could also be possible using this
                            platform.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <div className="d-lg-flex justify-content-center">
                            <img src={Group48} className="img-fluid rounded-start" alt="..."/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section className="Features bg-black pt-5" id="Features">
            <div className="container pt-5 text-center text-white">
                <div className="row pt-5">
                    <div className="col-md-6 d-flex align-items-center">
                        <div className="container-fluid d-lg-flex justify-content-center">
                            <img src={Group53} className="img-fluid rounded-start" alt="..."/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="section-heading">
                            <h2 className="fw-bold display-5 pb-5">Features</h2>
                        </div>
                        <h4>Experience Realtime Communication</h4>
                        <p className="pt-4">
                            Urgent repairs are time critical; Alternative solution to be found quickly.
                            Platform provides secure and personalized chat.
                            No more phone calls, managing multiple phone numbers, notebooks and
                            search for contacts. Chat history is saved, so when you can respond in your
                            suitable time.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section className="Features bg-black pt-5" id="Features">
            <div className="container pt-5 text-center text-white">
                <div className="row pt-5">
                    <div className="col-md-6">
                        <div className="section-heading">
                            <h2 className="fw-bold display-5 pb-5">Features</h2>
                        </div>
                        <h4>Experience Automation</h4>
                        <p className="pt-4">
                            It is an intelligent platform, supported by cutting edge automations
                            technologies running silently behind the scene. Helping you to
                            automate various actions to efficiently run day to day service activities
                            in easier manner.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <div className="d-lg-flex justify-content-center">
                            <img src={Group52} className="img-fluid rounded-start" alt="..."/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
